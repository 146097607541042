import * as React from 'react'
import { motion } from 'framer-motion'

function SvgTemperatureSensor(props) {
  return (
    <svg
      viewBox="0 0 2000 1400"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <defs>
        <linearGradient
          y1={420.217}
          id="temperature_sensor_svg__a"
          x1={-248.133}
          y2={420.217}
          x2={2262.69}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={231.5}
          id="temperature_sensor_svg__b"
          x1={112.5}
          y2={231.5}
          x2={112.5}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-51.198 -386.932) scale(2.05111)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={515.941}
          id="temperature_sensor_svg__c"
          x1={126.431}
          y2={515.941}
          x2={156.534}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-36.834 -262.701) scale(1.70339)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={458.587}
          id="temperature_sensor_svg__d"
          x1={136.849}
          y2={458.587}
          x2={777.998}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(2.81105 0 0 2.05111 -155.195 -386.932)"
        >
          <stop stopOpacity={0.303} stopColor="#e3dedf" offset={0} />
          <stop stopOpacity={0.375} stopColor="#2196f3" offset={0.965} />
        </linearGradient>
      </defs>
      <g>
        <path
          d="M-104.335 827.644c-.93-5.581-1.361-11.258-1.41-16.917-.445-51.222-1.81-102.444-1.409-153.666.07-8.978 2.094-17.838 2.819-26.786 2.115-26.082 2.82-52.795 2.82-78.948 0-34.26 3.232-70.278-1.41-104.324-2.785-20.425-9.477-40.282-12.688-60.621-4.023-25.477-3.927-51.856-5.639-77.538-1.627-24.403 6.614-47.916 8.458-71.899.913-11.857-.674-23.478-1.409-35.244-1.048-16.764 0-33.955 0-50.752 0-17.232-1.147-34.965 0-52.162.787-11.807 4.616-22.485 7.049-33.835 3.032-14.153 4.229-30.701 4.229-45.113"
          fill="#3f3d56"
        />
        <path
          d="M-255.939 1387.46s444.134-380.07 812.792-353.99c368.659 26.08 403.198-225.21 710.527-289.003 307.34-63.792 454.31 126.176 686.57 45.113 232.26-81.062 308.74-352.445 308.74-352.445l-5.64-518.8-2505.183-122.652"
          // fill="url(#temperature_sensor_svg__a)"
          fill="#fff"
        />
      </g>
      <g>
        <path
          d="M179.552 85.85v4.103-4.103z"
          fill="url(#temperature_sensor_svg__b)"
        />
      
          <path
            stroke="#e3dedf"
            strokeWidth={1}
            d="M229.804 518.821v461.468c0 15.542-11.479 28.141-25.639 28.141h0c-14.16 0-25.638-12.599-25.638-28.141V252.007c0-15.542 11.478-28.141 25.638-28.141h0c14.16 0 25.639 12.599 25.639 28.141v266.814"
            fill="url(#temperature_sensor_svg__c)"
          />
       
        <g fillOpacity={0.7} fill="#35b775">
          <path d="M123.519 258.521h72.814c7.646 0 13.845 6.198 13.845 13.845 0 7.646-6.199 13.845-13.845 13.845h-72.814c-7.647 0-13.845-6.199-13.845-13.845 0-7.647 6.198-13.845 13.845-13.845zM123.519 331.686h72.814c7.646 0 13.845 6.198 13.845 13.845 0 7.646-6.199 13.845-13.845 13.845h-72.814c-7.647 0-13.845-6.199-13.845-13.845 0-7.647 6.198-13.845 13.845-13.845zM123.519 395.349h72.814c7.646 0 13.845 6.199 13.845 13.845 0 7.646-6.199 13.845-13.845 13.845h-72.814c-7.647 0-13.845-6.199-13.845-13.845 0-7.646 6.198-13.845 13.845-13.845zM123.519 465.663h72.814c7.646 0 13.845 6.199 13.845 13.845 0 7.647-6.199 13.845-13.845 13.845h-72.814c-7.647 0-13.845-6.198-13.845-13.845 0-7.646 6.198-13.845 13.845-13.845zM123.519 531.227h72.814c7.646 0 13.845 6.198 13.845 13.845 0 7.646-6.199 13.845-13.845 13.845h-72.814c-7.647 0-13.845-6.199-13.845-13.845 0-7.647 6.198-13.845 13.845-13.845zM123.519 600.591h72.814c7.646 0 13.845 6.199 13.845 13.845 0 7.646-6.199 13.845-13.845 13.845h-72.814c-7.647 0-13.845-6.199-13.845-13.845 0-7.646 6.198-13.845 13.845-13.845zM123.519 668.055h72.814c7.646 0 13.845 6.199 13.845 13.845 0 7.646-6.199 13.845-13.845 13.845h-72.814c-7.647 0-13.845-6.199-13.845-13.845 0-7.646 6.198-13.845 13.845-13.845z" />
        </g>
        <path
          d="M124.592 730.769h72.814c7.647 0 13.845 6.199 13.845 13.846 0 7.646-6.198 13.845-13.845 13.845h-72.814c-7.647 0-13.845-6.199-13.845-13.845 0-7.647 6.198-13.846 13.845-13.846zM124.592 803.935h72.814c7.647 0 13.845 6.198 13.845 13.845 0 7.646-6.198 13.845-13.845 13.845h-72.814c-7.647 0-13.845-6.199-13.845-13.845 0-7.647 6.198-13.845 13.845-13.845z"
          fillOpacity={0.7}
          fill="#35b775"
        />
        <path
          stroke="#2196f3"
          strokeWidth={4.3}
          d="M124.592 867.598h72.814c7.647 0 13.845 6.198 13.845 13.845h0c0 7.646-6.198 13.845-13.845 13.845h-72.814c-7.647 0-13.845-6.199-13.845-13.845h0c0-7.647 6.198-13.845 13.845-13.845z"
          fill="#2196f3"
          fillOpacity={0.586}
        />
        <path
          stroke="#e3dedf"
          strokeWidth={4.3}
          d="M124.592 937.912h72.814c7.647 0 13.845 6.199 13.845 13.845h0c0 7.646-6.198 13.845-13.845 13.845h-72.814c-7.647 0-13.845-6.199-13.845-13.845h0c0-7.646 6.198-13.845 13.845-13.845z"
          fill="#fff"
          fillOpacity={0.523}
        />
        <path
          stroke="#2196f3"
          strokeWidth={4.3}
          d="M229.494 465.852s135.108-180.3 308.631-159.633c173.524 20.667 203.15 238.262 385.465 242.3 182.31 4.038 233.92 320.433 403.21 233.727 169.29-86.705 128.76-596.464 273.96-580.548 145.2 15.916 142.59 178.874 247.42 184.338 104.83 5.463 171.9-162.484 171.9-162.484l12.6 479.456-1803.186.393"
          fill="url(#temperature_sensor_svg__d)"
          opacity={0.887}
        />
        <path d="M230.496 703.9H1549.04" fill="none" />
        <path d="M232.28 701.224l1312.7 1.784" opacity={0.455} fill="none" />
        <path
          stroke="#3f3d56"
          strokeWidth={4.3}
          d="M232.015 702.116l1779.285-.892"
          fill="none"
        />

        <motion.g animate={{ x: [2550, 0] }} transition={{ duration: 3 }}>
          <motion.g
            animate={{
              scale: [1, 2, 2, 1, 1],
              rotate: [0, 0, 270, 270, 0],
            }}
            transition={{ duration: 5, loop: Infinity, delay: 4 }}
          >
            <path
              d="M424.048 312.734c0-11.218 9.918-20.312 22.152-20.312 12.234 0 22.152 9.094 22.152 20.312 0 11.218-9.918 20.313-22.152 20.313-12.234 0-22.152-9.095-22.152-20.313zM731.495 459.941c0-11.218 9.918-20.313 22.152-20.313 12.234 0 22.152 9.095 22.152 20.313 0 11.218-9.918 20.312-22.152 20.312-12.234 0-22.152-9.094-22.152-20.312zM1633.34 214.276c0-11.218 9.92-20.312 22.16-20.312 12.23 0 22.15 9.094 22.15 20.312 0 11.218-9.92 20.313-22.15 20.313-12.24 0-22.16-9.095-22.16-20.313zM1111.64 700.302c0-11.219 9.92-20.313 22.15-20.313 12.24 0 22.16 9.094 22.16 20.313 0 11.218-9.92 20.312-22.16 20.312-12.23 0-22.15-9.094-22.15-20.312zM1462.32 408.386c0-11.219 9.92-20.313 22.15-20.313 12.24 0 22.16 9.094 22.16 20.313 0 11.218-9.92 20.312-22.16 20.312-12.23 0-22.15-9.094-22.15-20.312z"
              fill="#3f3d56"
            />
          </motion.g>
        </motion.g>
      </g>
    </svg>
  )
}

export default SvgTemperatureSensor
