import React from 'react'
import Link from '../../utilities/link'
import { withTranslation } from 'react-i18next'

class SensorOrder extends React.Component {
  constructor() {
    super()
    this.state = {
      prodotto: '',
      nazione: '',
      qta: 0,
      importo: 0,
      importoTotale: 0,
    }
  }

  handleNazioneChange = event => {
    this.setState({ nazione: event.target.value })
  }

  handleQtaChange = event => {
    this.setState(
      {
        qta: event.target.value,
      },
      () => {
        console.log(this.state.qta)
        if (this.props.prodotto.includes('100')) {
          if (this.state.qta > 0 && this.state.qta <= 5) {
            this.setState({ importo: 33 }, () => {
              console.log(this.state.importo)
              this.setState(
                { importoTotale: this.state.qta * this.state.importo },
                () => {
                  console.log(this.state.importoTotale)
                }
              )
            })
          } else if (this.state.qta > 5 && this.state.qta <= 25) {
            this.setState({ importo: 29 }, () => {
              console.log(this.state.importo)
              this.setState(
                { importoTotale: this.state.qta * this.state.importo },
                () => {
                  console.log(this.state.importoTotale)
                }
              )
            })
          } else if (this.state.qta > 25 && this.state.qta <= 100) {
            this.setState({ importo: 25 }, () => {
              console.log(this.state.importo)
              this.setState(
                { importoTotale: this.state.qta * this.state.importo },
                () => {
                  console.log(this.state.importoTotale)
                }
              )
            })
          } else if (this.state.qta > 100 && this.state.qta <= 500) {
            this.setState({ importo: 23 }, () => {
              console.log(this.state.importo)
              this.setState(
                { importoTotale: this.state.qta * this.state.importo },
                () => {
                  console.log(this.state.importoTotale)
                }
              )
            })
          } else if (this.state.qta > 500 && this.state.qta <= 1000) {
            this.setState({ importo: 19 }, () => {
              console.log(this.state.importo)
              this.setState(
                { importoTotale: this.state.qta * this.state.importo },
                () => {
                  console.log(this.state.importoTotale)
                }
              )
            })
          }
        } else {
          if (this.state.qta > 0 && this.state.qta <= 5) {
            this.setState({ importo: 67 }, () => {
              console.log(this.state.importo)
              this.setState(
                { importoTotale: this.state.qta * this.state.importo },
                () => {
                  console.log(this.state.importoTotale)
                }
              )
            })
          } else if (this.state.qta > 5 && this.state.qta <= 25) {
            this.setState({ importo: 61 }, () => {
              console.log(this.state.importo)
              this.setState(
                { importoTotale: this.state.qta * this.state.importo },
                () => {
                  console.log(this.state.importoTotale)
                }
              )
            })
          } else if (this.state.qta > 25 && this.state.qta <= 100) {
            this.setState({ importo: 53 }, () => {
              console.log(this.state.importo)
              this.setState(
                { importoTotale: this.state.qta * this.state.importo },
                () => {
                  console.log(this.state.importoTotale)
                }
              )
            })
          } else if (this.state.qta > 100 && this.state.qta <= 500) {
            this.setState({ importo: 47 }, () => {
              console.log(this.state.importo)
              this.setState(
                { importoTotale: this.state.qta * this.state.importo },
                () => {
                  console.log(this.state.importoTotale)
                }
              )
            })
          } else if (this.state.qta > 500 && this.state.qta <= 1000) {
            this.setState({ importo: 41 }, () => {
              console.log(this.state.importo)
              this.setState(
                { importoTotale: this.state.qta * this.state.importo },
                () => {
                  console.log(this.state.importoTotale)
                }
              )
            })
          }
        }
      }
    )
  }

  render() {
    const { t } = this.props
    let lang =
      typeof window !== `undefined`
        ? window.location.pathname.split('/')[1]
        : this.props.i18n.language.split('-')[0]

    return (
      <div
        className="section is-lightgrey has-text-centered"
        style={{ padding: '0' }}
      >
        <section className="hero is-gradient">
          <div className="hero-body" style={{ padding: '2rem 1.5rem 0 1.5rem' }}>
            <div className="container">
              {/* <h1 className="title is-2 is-white">Inolta il tuo ordine</h1> */}
              <h2
                className="subtitle is-5 is-white"
                style={{
                  marginBottom: '-20px',
                  padding: '10px',
                  backgroundColor: 'RGBA(33, 150, 243,1)',
                }}
              >
                {t('sensorOrderForm.callToAction')}
              </h2>
            </div>
          </div>
        </section>
        <div className="container" style={{ padding: '40px 50px' }}>
          <form
            method="POST"
            name="sensororder"
            netlify-honeypot="bot-field"
            data-netlify="true"
            action={`/${lang}/grazie`}
          >
            <input type="hidden" name="form-name" value="sensororder" />
            <input type="hidden" name="bot-field" />
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field">
                  <label className="label">
                    <small>{t('sensorOrderForm.idProdotto')}</small>
                  </label>
                  <input
                    className="input"
                    type="text"
                    name="prodotto"
                    value={this.props.prodotto}
                    readonly
                    // onChange={this.handleProdottoChange}
                  ></input>
                </div>

                <div className="field">
                  <label className="label">
                    <small>{t('sensorOrderForm.qta')}</small>
                  </label>
                  <div
                    className="select"
                    name="qta"
                    onChange={this.handleQtaChange}
                    value={this.state.qta}
                    // placeholder="Quantità"
                    required
                  >
                    <select name="quantity">
                      <option value={0}>{'-'}</option>
                      <option value={1}>{'1'}</option>
                      <option value={5}>{'5'}</option>
                      <option value={10}>{'10'}</option>
                      <option value={25}>{'25'}</option>
                      <option value={50}>{'50'}</option>
                      <option value={100}>{'100'}</option>
                      <option value={250}>{'250'}</option>
                      <option value={500}>{'500'}</option>
                      <option value={1000}>{'1000'}</option>
                    </select>
                  </div>
                </div>

                <div className="field">
                  <label className="label">
                    <small>{t('sensorOrderForm.importoUnitario')}</small>
                  </label>
                  <input
                    className="input"
                    type="text"
                    name="importo"
                    value={this.state.importo}
                    readonly
                    // onChange={this.handleProdottoChange}
                  ></input>
                </div>

                <div className="field">
                  <label className="label">
                    <small>{t('sensorOrderForm.importoTotale')}</small>
                  </label>
                  <input
                    className="input"
                    type="text"
                    name="importo"
                    value={this.state.importoTotale}
                    readonly
                    // onChange={this.handleProdottoChange}
                  ></input>
                </div>
              </div>
            </div>
            <p>
              <small>
                {t('sensorOrderForm.notaIVAeTrasporto')} <br />
                ------------------
              </small>
            </p>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field">
                  <input
                    className="input"
                    type="text"
                    name="ragionesociale"
                    placeholder={t('sensorOrderForm.ragioneSociale')}
                    required
                  ></input>
                </div>

                <div className="field">
                  <input
                    className="input"
                    type="text"
                    name="indirizzo"
                    placeholder={t('sensorOrderForm.indirizzo')}
                    required
                  ></input>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field">
                  <input
                    className="input"
                    type="text"
                    name="cap"
                    placeholder={t('sensorOrderForm.cap')}
                    required
                  ></input>
                </div>
                <div className="field">
                  <input
                    className="input"
                    type="text"
                    name="citta"
                    placeholder={t('sensorOrderForm.citta')}
                    required
                  ></input>
                </div>
                <div className="field">
                  <input
                    className="input"
                    type="text"
                    name="nazione"
                    placeholder={t('sensorOrderForm.nazione')}
                    onChange={this.handleNazioneChange}
                    required
                  ></input>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field">
                  <input
                    className="input"
                    type="text"
                    name="piva"
                    placeholder={t('sensorOrderForm.piva')}
                    required
                  ></input>
                </div>
                {this.state.nazione === 'Italia' && (
                  <>
                    <div className="field">
                      <input
                        className="input"
                        type="text"
                        name="indirizzo"
                        placeholder={t('sensorOrderForm.cf')}
                        required
                      ></input>
                    </div>
                    <div className="field">
                      <input
                        className="input"
                        type="email"
                        name="cap"
                        placeholder={t('sensorOrderForm.sdi')}
                        required
                      ></input>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field">
                  <input
                    className="input"
                    type="email"
                    name="email"
                    placeholder={t('sensorOrderForm.email')}
                    required
                  ></input>
                </div>

                <div className="field">
                  <input
                    className="input"
                    type="text"
                    name="telefono"
                    placeholder={t('sensorOrderForm.tel')}
                    required
                  ></input>
                </div>
                <div className="field">
                  <input
                    className="input"
                    type="text"
                    name="riferimento"
                    placeholder={t('sensorOrderForm.rif')}
                    required
                  ></input>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">
                <small>{t('sensorOrderForm.note')}</small>
              </label>
              <div className="control">
                <textarea
                  className="textarea"
                  name="note"
                  placeholder={t('sensorOrderForm.notePlaceholder')}
                ></textarea>
              </div>
            </div>

            <div className="field">
              <div className="control">
                <label className="checkbox">
                  <input type="checkbox" name="privacy" />
                  <small>
                    {' '}
                    {<>{t('projectFormFields.privacy')}</>}{' '}
                    <Link to={`/privacy`} style={{ color: '#444' }}>
                      Privacy Policy.
                    </Link>
                  </small>
                </label>
              </div>
            </div>

            <button
              type="submit"
              className="button is-primary is-medium is-fullwidth"
            >
              {t('sensorOrderForm.invia')}
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export default withTranslation()(SensorOrder)
