import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../../components/seo/seo'
import SiteNavigation from '../../components/header/siteNavigation'
import ProductHeader from '../../components/header/productHeader'
import ProductOnShow from '../../components/common/productOnShow'
import ProductsDisplay from '../../components/common/productsDisplay'
import CommonFooter from '../../components/footer/commonFooter'
import StylishLinks from '../../components/common/stylishLinks'
import ElectronicsDevelopment from '../../illustrations/ProjectEngineeringGoalSetting'
import '../../layouts/custom.scss'
import SvgTemperatureSensor from '../../illustrations/Products/TemperatureSensor'
import SensorOrder from '../../components/forms/sensorOrder'
import ProductInfoForm from '../../components/forms/productInfoForm'
import { useTranslation } from 'react-i18next'
import { usePageContext } from '../../pageContext'
import ElegantHeader from '../../components/header/elegantHeader'
import { StaticImage } from 'gatsby-plugin-image'

const SensorsForElectronicsIT = ({ data }) => {
  const { t } = useTranslation();

  
  return (
  <>
    <Seo
      title={t('Sensors.seoTitle')}
      description={t('Sensors.seoDescription')}
      seoImage={data.sensorelivello.childImageSharp.resize.src}
    />
    <SiteNavigation />
    <ElegantHeader
        title={t('Sensors.headerTitle')}
        subtitle={t('Sensors.headerSubtitle')}
        image={<StaticImage className="bg-white rounded-full" src="../../images/sensoristica/temperature_sensors_for_electronics.png" width={400} alt={t('Sensors.headerTitle')} />}
    />
    <ProductsDisplay
      product1={
        <ProductOnShow
          productImage={[data.sensoretemp.childImageSharp.fixed]}
          imageWidth="280"
          productImageAlt={[t('Sensors.alt1')]}
          productTitle={t('Sensors.product1')}
          productDescription={t('Sensors.productDescription1')}
          features={[1, 1, 1, 1, 1, 1]}
          featureTitle={[
            <>{t('Sensors.featureTitle1')}</>,
            <>{t('Sensors.featureTitle2')}</>,
            <>{t('Sensors.featureTitle3')}</>,
            <>{t('Sensors.featureTitle4')}</>,
            <>{t('Sensors.featureTitle5')}</>,
            <>{t('Sensors.featureTitle6')}</>,
          ]}
          featureDesc={[
            <>{t('Sensors.featureDesc1')}</>,
            <>{t('Sensors.featureDesc2')}</>,
            <>{t('Sensors.featureDesc3')}</>,
            <>{t('Sensors.featureDesc4')}</>,
            <>{t('Sensors.featureDesc5')}</>,
            <>{t('Sensors.featureDesc6')}</>,
          ]}
          tagText={t('Sensors.tagText')}
          productInfoCTA={t('Sensors.productInfoCTA')}
          productOrderCTA={t('Sensors.productCTA')}
          // form={<SensorOrder prodotto="Sonda T 0:100" />}
          formInfo={<ProductInfoForm />}
          emailSubject="subject=Ordine Sonde di Temperatura"
        />
      }
      product2={
        <ProductOnShow
          productImage={[data.sensoretemp.childImageSharp.fixed]}
          imageWidth="280"
          productImageAlt={[t('Sensors.alt2')]}
          productTitle={t('Sensors.product2')}
          productDescription={t('Sensors.productDescription2')}
          features={[1, 1, 1, 1, 1, 1]}
          featureTitle={[
            <>{t('Sensors.feature2Title1')}</>,
            <>{t('Sensors.feature2Title2')}</>,
            <>{t('Sensors.feature2Title3')}</>,
            <>{t('Sensors.feature2Title4')}</>,
            <>{t('Sensors.feature2Title5')}</>,
            <>{t('Sensors.feature2Title6')}</>,
          ]}
          featureDesc={[
            <>{t('Sensors.feature2Desc1')}</>,
            <>{t('Sensors.feature2Desc2')}</>,
            <>{t('Sensors.feature2Desc3')}</>,
            <>{t('Sensors.feature2Desc4')}</>,
            <>{t('Sensors.feature2Desc5')}</>,
            <>{t('Sensors.feature2Desc6')}</>,
          ]}
          tagText={t('Sensors.tagText')}
          productInfoCTA={t('Sensors.productInfoCTA')}
          productOrderCTA={t('Sensors.productCTA')}
          // form={<SensorOrder prodotto="Sonda T -45:+130" />}
          formInfo={<ProductInfoForm />}
          emailSubject="subject=Ordine Sonde di Temperatura"
        />
      }
    />

    <StylishLinks
      animatedIllustration={<ElectronicsDevelopment />}
      linkOneTitle={t('Sensors.linkOneTitle')}
      linkOneText={t('Sensors.linkOneText')}
      linkOne={t('Sensors.linkOne')}
      to1="/contatti"
      linkTwoTitle={t('Sensors.linkTwoTitle')}
      linkTwoText={t('Sensors.linkTwoText')}
      linkTwo={t('Sensors.linkTwo')}
      to2internal="/contatti"
    />
    <CommonFooter />
  </>
)
}

export default SensorsForElectronicsIT

export const sensorsQuery = graphql`
  query {
    sensorelivello: file(
      relativePath: { eq: "sensoristica/water_level_sensor.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1950) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
    sensore: file(relativePath: { eq: "icons/Sensoristica.png" }) {
      childImageSharp {
        fixed(width: 180) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sensoretemp: file(
      relativePath: { eq: "sensoristica/sonda_di_temperatura.jpg" }
    ) {
      childImageSharp {
        fixed(width: 280) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
